import React, { useState, useContext } from 'react';
import { useForm } from "react-hook-form";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import SignatureCustomer from '../signature/SignatureCustomer';
import SignatureSalesStaff from '../signature/SignatureSalesStaff';
import SignatureDesigner from '../signature/SignatureDesigner';
import SignatureConstructionManeger from '../signature/SignatureConstructionManeger';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import useAuthAxios from "../../useAuthAxios";
import { ConfirmationContext } from "../../Confirmation";
import { Contents } from '../css/CSS.js';
import LoadingDialog from '../Loading';
import Typography from '@mui/material/Typography';
import { UserInputData } from './StartRopeFrame';

export default function Review(props) {
    const { handleSubmit } = useForm({});
    const authAxios = useAuthAxios();
    const navigate = useNavigate();
    const { kojiCD, signCustomer } = useContext(ConfirmationContext);

    const [processing_post, setProcessing_post] = useState(false);
    const onSubmit = (action) => {

        if (action === "post") {
            navigate("/confirmation/chakko/chakko")
        } else if (action === "back") {
            props.handleBack();
        }
    }

    const [pdf, setPdf] = React.useState()
    React.useEffect(() => {
        setProcessing_post(true)
        authAxios
            .get('api/confirmation/chakko/' + kojiCD + '/report?order=0&fullset=1')
            .then(response => {
                const data = response.data;
                const pdfdata = JSON.parse((JSON.stringify(data)))
                setPdf("data:application/pdf;base64," + pdfdata.confirmationPdfData64)
                setProcessing_post(false)
            });
        setTimeout(() => {
            setProcessing_post(false);
        }, 10000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { currentState } = useContext(UserInputData);

    const inputKakunin1 = {
        kiroku: currentState.kiroku,
        kyokaiPoint: currentState.kakunin.kyokaiPoint,
        kyokaiPointKakunin: currentState.kakunin.kyokaiPointKakunin,
        haichi: currentState.kakunin.haichi,
        haichiHenkoUmu: currentState.kakunin.haichiHenkoUmu,
        haichiHenko1Side: currentState.kakunin.haichiHenko1Side,
        haichiHenko2Side: currentState.kakunin.haichiHenko2Side,
        houkisei: currentState.kakunin.houkisei,
        houKitaShasen: currentState.kakunin.houKitaShasen,
        houDoroShasen: currentState.kakunin.houDoroShasen,
        houTakasaSeigen: currentState.kakunin.houTakasaSeigen,
        houSaikou: currentState.kakunin.houSaikou,
        houHekimenKoutai: currentState.kakunin.houHekimenKoutai,
        houEnshoLine: currentState.kakunin.houEnshoLine,
        houMinpoRikaku500: currentState.kakunin.houMinpoRikaku500,
        sekkeiGLKakunin: currentState.kakunin.sekkeiGLKakunin,
        sekkeiGLBM: currentState.kakunin.sekkeiGLBM,
        sekkeiGLHenko: currentState.kakunin.sekkeiGLHenko,
        sekkeiGLHenkoUmu: currentState.kakunin.sekkeiGLHenkoUmu,
    }
    const inputKakunin2 = {
        jibanKairyo: currentState.kakunin.jibanKairyo,
        jibanKairyoUmu: currentState.kakunin.jibanKairyoUmu,
        jibanHyuoso: currentState.kakunin.jibanHyuoso,
        jibanKoukankui: currentState.kakunin.jibanKoukankui,
        jibanTigerPile: currentState.kakunin.jibanTigerPile,
        jibanKiseiConcreteKui: currentState.kakunin.jibanKiseiConcreteKui,
        jibanPurePileKouho: currentState.kakunin.jibanPurePileKouho,
        jibanSonota: currentState.kakunin.jibanSonota,
        kakuninShinsei: currentState.kakunin.kakuninShinsei,
        kakuninShinseiSts: currentState.kakunin.kakuninShinseiSts,
        zandoShori: currentState.kakunin.zandoShori,
        zandoShoriUmu: currentState.kakunin.zandoShoriUmu,
        senkoGaikoKoji: currentState.kakunin.senkoGaikoKoji,
        senkoGaikoKojiUmu: currentState.kakunin.senkoGaikoKojiUmu,
        pouchIchi: currentState.kakunin.pouchIchi,
        meterIchi: currentState.kakunin.meterIchi,
        meterSuido: currentState.kakunin.meterSuido,
        meterDenki: currentState.kakunin.meterDenki,
        meterGas: currentState.kakunin.meterGas,
        kyuhaisuiRoute: currentState.kakunin.kyuhaisuiRoute,
        kyuhaisuiUsuiMas: currentState.kakunin.kyuhaisuiUsuiMas,
        kyuhaisuiOsuiMas: currentState.kakunin.kyuhaisuiOsuiMas,
        kyuhaisuiSaishuMas: currentState.kakunin.kyuhaisuiSaishuMas,
        kyuhaisuiJokasou: currentState.kakunin.kyuhaisuiJokasou,
        kyuhaisuiRissuisen: currentState.kakunin.kyuhaisuiRissuisen,
        kyuhaisuiSansuisen: currentState.kakunin.kyuhaisuiSansuisen,
        acShitugaiki: currentState.kakunin.acShitugaiki,
        ecoCuteEneFarm: currentState.kakunin.ecoCuteEneFarm,
    }
    const inputKouki = {
        koteihyoKakunin: currentState.kouki.koteihyoKakunin,

    }
    const inputRyuui = {
        ryuui1: currentState.ryuui.ryuui1,
        ryuui2: currentState.ryuui.ryuui2,
        ryuui3: currentState.ryuui.ryuui3,
    }
    const inputGenbaKensa = {
        ninteiTeitanso: currentState.genbaKensa.ninteiTeitanso,
        sumaiKyufu: currentState.genbaKensa.sumaiKyufu,
        jyutakuSeinoHyoji: currentState.genbaKensa.jyutakuSeinoHyoji,
        flat35: currentState.genbaKensa.flat35,
        chokiYuryoNintei: currentState.genbaKensa.chokiYuryoNintei,
        chukanKensa: currentState.genbaKensa.chukanKensa,
        kanryoKensa: currentState.genbaKensa.kanryoKensa,
        sonotaKensa: currentState.genbaKensa.sonotaKensa,
    }

    const [checkKakuninCheck, setCheckKakuninCheck] = useState(0)
    const [checkKakunin2Check, setCheckKakunin2Check] = useState(0)
    const [checkKoukiCheck, setCheckKoukiCheck] = useState(0)
    const [checkRyuuiCheck, setCheckRyuuiCheck] = useState(0)
    const [checkGenbaKensaCheck, setCheckGenbaKensaCheck] = useState(0)

    React.useEffect(() => {

        if (inputKakunin1.kiroku === 0) { setCheckKakuninCheck(checkKakuninCheck + 1) }
        if (inputKakunin1.kyokaiPoint === 0) {
            setCheckKakuninCheck(checkKakuninCheck + 1)
        } else if (inputKakunin1.kyokaiPoint === 1) {
            if (inputKakunin1.kyokaiPointKakunin === 0) { setCheckKakuninCheck(checkKakuninCheck + 1) }
        }

        if (inputKakunin1.haichi === 0) {
            setCheckKakuninCheck(checkKakuninCheck + 1)
        } else if (inputKakunin1.haichi === 1) {
            if (inputKakunin1.haichiHenkoUmu === 0) { setCheckKakuninCheck(checkKakuninCheck + 1) }
            else if (inputKakunin1.haichiHenkoUmu === 2) {
                if (inputKakunin1.haichiHenko1Side === 0) { setCheckKakuninCheck(checkKakuninCheck + 1) }
                if (inputKakunin1.haichiHenko2Side === 0) { setCheckKakuninCheck(checkKakuninCheck + 1) }
            }
        }
        if (inputKakunin1.houkisei === 0) {
            setCheckKakuninCheck(checkKakuninCheck + 1)
        } else if (inputKakunin1.houkisei === 1) {
            if (inputKakunin1.houKitaShasen === 0) { setCheckKakuninCheck(checkKakuninCheck + 1) }
            if (inputKakunin1.houDoroShasen === 0) { setCheckKakuninCheck(checkKakuninCheck + 1) }
            if (inputKakunin1.houTakasaSeigen === 0) { setCheckKakuninCheck(checkKakuninCheck + 1) }
            if (inputKakunin1.houSaikou === 0) { setCheckKakuninCheck(checkKakuninCheck + 1) }
            if (inputKakunin1.houHekimenKoutai === 0) { setCheckKakuninCheck(checkKakuninCheck + 1) }
            if (inputKakunin1.houEnshoLine === 0) { setCheckKakuninCheck(checkKakuninCheck + 1) }
            if (inputKakunin1.houMinpoRikaku500 === 0) { setCheckKakuninCheck(checkKakuninCheck + 1) }
        }

        if (inputKakunin1.sekkeiGLKakunin === 0) {
            setCheckKakuninCheck(checkKakuninCheck + 1)
        } else if (inputKakunin1.sekkeiGLKakunin === 1) {
            if (inputKakunin1.sekkeiGLBM === 0) { setCheckKakuninCheck(checkKakuninCheck + 1) }
            if (inputKakunin1.sekkeiGLHenko === 0) { setCheckKakuninCheck(checkKakuninCheck + 1) }
            else if (inputKakunin1.sekkeiGLHenko === 1) {
                if (inputKakunin1.sekkeiGLHenkoUmu === 0) { setCheckKakuninCheck(checkKakuninCheck + 1) }
            }
        }

        if (inputKakunin2.jibanKairyo === 0) {
            setCheckKakunin2Check(checkKakunin2Check + 1)
        } else if (inputKakunin2.jibanKairyo === 1) {
            if (inputKakunin2.jibanKairyoUmu === 0) { setCheckKakunin2Check(checkKakunin2Check + 1) }
            else if (inputKakunin2.jibanKairyoUmu === 2) {
                if (inputKakunin2.jibanHyuoso === 0) { setCheckKakunin2Check(checkKakunin2Check + 1) }
                if (inputKakunin2.jibanKoukankui === 0) { setCheckKakunin2Check(checkKakunin2Check + 1) }
                if (inputKakunin2.jibanTigerPile === 0) { setCheckKakunin2Check(checkKakunin2Check + 1) }
                if (inputKakunin2.jibanKiseiConcreteKui === 0) { setCheckKakunin2Check(checkKakunin2Check + 1) }
                if (inputKakunin2.jibanPurePileKouho === 0) { setCheckKakunin2Check(checkKakunin2Check + 1) }
                if (inputKakunin2.jibanSonota === 0) { setCheckKakunin2Check(checkKakunin2Check + 1) }
            }
        }
        if (inputKakunin2.kakuninShinsei === 0) {
            setCheckKakunin2Check(checkKakunin2Check + 1)
        } else if (inputKakunin2.kakuninShinsei === 1) {
            if (inputKakunin2.kakuninShinseiSts === 0) { setCheckKakunin2Check(checkKakunin2Check + 1) }
        }
        if (inputKakunin2.zandoShori === 0) {
            setCheckKakunin2Check(checkKakunin2Check + 1)
        } else if (inputKakunin2.zandoShori === 1) {
            if (inputKakunin2.zandoShoriUmu === 0) { setCheckKakunin2Check(checkKakunin2Check + 1) }
        }
        if (inputKakunin2.senkoGaikoKoji === 0) {
            setCheckKakunin2Check(checkKakunin2Check + 1)
        } else if (inputKakunin2.senkoGaikoKoji === 1) {
            if (inputKakunin2.senkoGaikoKojiUmu === 0) { setCheckKakunin2Check(checkKakunin2Check + 1) }
        }
        if (inputKakunin2.pouchIchi === 0) { setCheckKakunin2Check(checkKakunin2Check + 1) }
        if (inputKakunin2.meterIchi === 0) {
            setCheckKakunin2Check(checkKakunin2Check + 1)
        } else if (inputKakunin2.meterIchi === 1) {
            if (inputKakunin2.meterSuido === 0) { setCheckKakunin2Check(checkKakunin2Check + 1) }
            if (inputKakunin2.meterDenki === 0) { setCheckKakunin2Check(checkKakunin2Check + 1) }
            if (inputKakunin2.meterGas === 0) { setCheckKakunin2Check(checkKakunin2Check + 1) }
        }
        if (inputKakunin2.kyuhaisuiRoute === 0) {
            setCheckKakunin2Check(checkKakunin2Check + 1)
        } else if (inputKakunin2.kyuhaisuiRoute === 1) {
            if (inputKakunin2.kyuhaisuiUsuiMas === 0) { setCheckKakunin2Check(checkKakunin2Check + 1) }
            if (inputKakunin2.kyuhaisuiOsuiMas === 0) { setCheckKakunin2Check(checkKakunin2Check + 1) }
            if (inputKakunin2.kyuhaisuiSaishuMas === 0) { setCheckKakunin2Check(checkKakunin2Check + 1) }
            if (inputKakunin2.kyuhaisuiJokasou === 0) { setCheckKakunin2Check(checkKakunin2Check + 1) }
            if (inputKakunin2.kyuhaisuiRissuisen === 0) { setCheckKakunin2Check(checkKakunin2Check + 1) }
            if (inputKakunin2.kyuhaisuiSansuisen === 0) { setCheckKakunin2Check(checkKakunin2Check + 1) }
        }
        if (inputKakunin2.acShitugaiki === 0) { setCheckKakunin2Check(checkKakunin2Check + 1) }
        if (inputKakunin2.ecoCuteEneFarm === 0) { setCheckKakunin2Check(checkKakunin2Check + 1) }
        if (inputKouki.koteihyoKakunin === 0) { setCheckKoukiCheck(checkKoukiCheck + 1) }
        if (inputRyuui.ryuui1 === 0) { setCheckRyuuiCheck(checkRyuuiCheck + 1) }
        if (inputRyuui.ryuui2 === 0) { setCheckRyuuiCheck(checkRyuuiCheck + 1) }
        if (inputRyuui.ryuui3 === 0) { setCheckRyuuiCheck(checkRyuuiCheck + 1) }
        if (inputGenbaKensa.ninteiTeitanso === 0) { setCheckGenbaKensaCheck(checkGenbaKensaCheck + 1) }
        if (inputGenbaKensa.sumaiKyufu === 0) { setCheckGenbaKensaCheck(checkGenbaKensaCheck + 1) }
        if (inputGenbaKensa.jyutakuSeinoHyoji === 0) { setCheckGenbaKensaCheck(checkGenbaKensaCheck + 1) }
        if (inputGenbaKensa.flat35 === 0) { setCheckGenbaKensaCheck(checkGenbaKensaCheck + 1) }
        if (inputGenbaKensa.chokiYuryoNintei === 0) { setCheckGenbaKensaCheck(checkGenbaKensaCheck + 1) }
        if (inputGenbaKensa.chukanKensa === 0) { setCheckGenbaKensaCheck(checkGenbaKensaCheck + 1) }
        if (inputGenbaKensa.kanryoKensa === 0) { setCheckGenbaKensaCheck(checkGenbaKensaCheck + 1) }
        if (inputGenbaKensa.sonotaKensa === 0) { setCheckGenbaKensaCheck(checkGenbaKensaCheck + 1) }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            {checkKakuninCheck === 0 && checkKakunin2Check === 0 && checkKoukiCheck === 0 && checkRyuuiCheck === 0 && checkGenbaKensaCheck === 0 ?
                <Contents>
                    <Grid sx={{ pt: 3, pb: 1 }}>
                        <Card sx={{ boxShadow: 0, minWidth: 300 }}>
                            <CardContent>
                                <Grid container spacing={3}>
                                    <Grid item xs={3} >
                                        <SignatureCustomer />
                                        {signCustomer === false ?
                                            <Typography sx={{ color: "#FF0000", pt: 1 }} >
                                                お客様サインは必須です
                                            </Typography> : ""
                                        }
                                    </Grid>
                                    <Grid item xs={3} >
                                        <SignatureSalesStaff />
                                    </Grid>
                                    <Grid item xs={3} >
                                        <SignatureDesigner />
                                    </Grid>
                                    <Grid item xs={3} >
                                        <SignatureConstructionManeger />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Card sx={{ width: "100%", p: 1, boxShadow: 0, border: 1, borderColor: "#EEEEEE" }} >
                        <Grid container alignItems="center" justify="center" sx={{ pt: 1 }}>
                            <Grid item xs={12}>
                                <embed type="application/pdf" src={pdf} width="100%" height="1000px" />
                            </Grid>
                        </Grid>
                    </Card>
                    <Grid item xs={12} sx={{ pt: 2 }}>
                        {signCustomer === true ?
                            <Card sx={{ boxShadow: 0 }}>
                                <CardContent style={{ display: 'flex', paddingBottom: 0, justifyContent: 'end', alignItems: 'end' }} >
                                    <form onSubmit={handleSubmit(onSubmit)} >
                                        <Grid container columnSpacing={{ xs: 1, sm: 3, md: 3 }}>
                                            <Grid item xs={6} >
                                                <Button variant="contained" color="primary" size="large" type="button" onClick={() => onSubmit("back")} >
                                                    戻る
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button variant="contained" color="primary" size="large" type="button" onClick={() => onSubmit("post")} disabled={processing_post}>
                                                    {processing_post ? "処理中" : "作成"}
                                                </Button>
                                                <LoadingDialog load={processing_post} />
                                            </Grid>
                                        </Grid>
                                    </form>
                                </CardContent>
                            </Card> :
                            <Card sx={{ boxShadow: 0 }}>
                                <CardContent style={{ display: 'flex', paddingBottom: 0, justifyContent: 'end', alignItems: 'end' }} >
                                    <form onSubmit={handleSubmit(onSubmit)} >
                                        <Grid container columnSpacing={{ xs: 1, sm: 3, md: 3 }}>
                                            <Grid item xs={6} >
                                                <Button variant="contained" color="primary" size="large" type="button" onClick={() => onSubmit("back")} >
                                                    戻る
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button variant="contained" color="primary" size="large" type="button" disabled={true}>
                                                    作成
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </CardContent>
                            </Card>
                        }
                    </Grid>
                </Contents> :
                <Contents component="main" >
                    <Grid container columns={12} rowSpacing={4} columnSpacing={{ xs: 1, sm: 3, md: 3 }} sx={{ pt: 3 }}>
                        <Grid item xs={12} sx={{ transform: "scale(1.5)" }}>
                            <Typography sx={{ color: "#FF0000", pl: 20 }}>
                                入力が不十分です。全てのチェック項目を入力してください。
                            </Typography>
                        </Grid>
                        {checkKakuninCheck > 0 ?
                            <Grid item xs={12} sx={{ transform: "scale(1.3)" }}>
                                <Typography sx={{ pl: 15 }}>
                                    確認事項1：未入力あり
                                </Typography>
                            </Grid> : null
                        }
                        {checkKakunin2Check > 0 ?
                            <Grid item xs={12} sx={{ transform: "scale(1.3)" }}>
                                <Typography sx={{ pl: 15 }}>
                                    確認事項2：未入力あり
                                </Typography>
                            </Grid> : null
                        }
                        {checkKoukiCheck > 0 ?
                            <Grid item xs={12} sx={{ transform: "scale(1.3)" }}>
                                <Typography sx={{ pl: 15 }}>
                                    工期：未入力あり
                                </Typography>
                            </Grid> : null
                        }
                        {checkRyuuiCheck > 0 ?
                            <Grid item xs={12} sx={{ transform: "scale(1.3)" }}>
                                <Typography sx={{ pl: 15 }}>
                                    工事着手のご留意事項：未入力あり
                                </Typography>
                            </Grid> : null
                        }
                        {checkGenbaKensaCheck > 0 ?
                            <Grid item xs={12} sx={{ transform: "scale(1.3)" }}>
                                <Typography sx={{ pl: 15 }}>
                                    現場検査：未入力あり
                                </Typography>
                            </Grid> : null
                        }
                        <Grid item xs={12} >
                            <Card sx={{ boxShadow: 0 }}>
                                <CardContent style={{ display: 'flex', paddingBottom: 0, justifyContent: 'end', alignItems: 'end' }} >
                                    <form onSubmit={handleSubmit(onSubmit)} >
                                        <Button variant="contained" color="primary" size="large" type="button" onClick={() => onSubmit("back")} >
                                            戻る
                                        </Button>
                                    </form>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} >
                            <Card sx={{ width: "100%", p: 1, boxShadow: 0, border: 1, borderColor: "#EEEEEE" }} >
                                <Grid container alignItems="center" justify="center" >
                                    <Grid item xs={12}>
                                        <embed type="application/pdf" src={pdf} width="100%" height="1000px" />
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} >
                        <Card sx={{ boxShadow: 0 }}>
                            <CardContent style={{ display: 'flex', paddingBottom: 0, justifyContent: 'end', alignItems: 'end' }} >
                                <form onSubmit={handleSubmit(onSubmit)} >
                                    <Button variant="contained" color="primary" size="large" type="button" onClick={() => onSubmit("back")} >
                                        戻る
                                    </Button>
                                </form>
                            </CardContent>
                        </Card>
                    </Grid>
                </Contents>
            }
        </React.Fragment >
    );
}