import React, { useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { useNavigate } from 'react-router-dom';
import BorderColorSharpIcon from '@mui/icons-material/BorderColorSharp';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import useAuthAxios from "../../useAuthAxios";
import { AuthContext } from "../../Auth";
import { ConfirmationContext } from "../../Confirmation";
import SearchIcon from "@mui/icons-material/Search";
import { useForm, Controller } from "react-hook-form";
import { ConfirmationContents } from '../css/CSS.js';
import Typography from '@mui/material/Typography';
import LoadingDialog from '../Loading';
import { ChakkoInitialInputData } from "../chakko/StartRopeFrame"
import { JyotoInitialInputData } from "../jyoto/JyotoFrame"
import { ShunkoInitialInputData } from "../shunko/ShunkoFrame"

const TableFlame = styled(TableContainer)({
    borderRadius: "10px 10px 0 0",
});
const Header = styled(TableRow)({
    backgroundColor: "#289bdf",
});
const Text = styled(Typography)({
    color: "#FFFFFF",
    transform: "scale(0.9)",
});

const CLTableCell = styled(TableCell)({
    padding: "5px",
});

const Works = styled(Button)({
    color: "#000000",
});

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
}

const headCells = [
    {
        id: 'customerCD',
        numeric: true,
        disablePadding: false,
        label: 'お客様No',
        align: 'left',
    },
    {
        id: 'kojiCD',
        numeric: true,
        disablePadding: true,
        label: '工事コード',
        align: 'left'
    },
    {
        id: 'agreeShitenName',
        numeric: true,
        disablePadding: true,
        label: '契約支店',
        align: 'left'
    },

    {
        id: 'buildShitenName',
        numeric: true,
        disablePadding: true,
        label: '施工支店',
        align: 'left'
    },
    {
        id: 'kojiName',
        numeric: true,
        disablePadding: false,
        label: '邸名',
        align: 'left'
    },
    {
        id: 'chakko',
        numeric: true,
        disablePadding: false,
        label: '着工（地縄）',
        align: 'center'
    },

    {
        id: 'jyoto',
        numeric: true,
        disablePadding: false,
        label: '上棟',
        align: 'center'
    },
    {
        id: 'shunko',
        numeric: true,
        disablePadding: false,
        label: '竣工',
        align: 'center'
    }
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <Header>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={headCell.id === 'customerCD' ? { pr: 0, pl: 1.5 } : { pr: 0, pl: 0 }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            <Text >{headCell.label}</Text>
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </Header>
        </TableHead >
    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number,
};

export default function ConfirmationList() {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const authAxios = useAuthAxios();
    const [list, setList] = useState()
    const { userShozokuCD } = useContext(AuthContext);
    const navigate = useNavigate();
    const headers = { "Authorization": "Bearer " + localStorage.getItem("Auth") };

    const [processing_setList, setProcessing_setList] = useState(false);
    React.useEffect(() => {
        setProcessing_setList(true)
        authAxios
            .get('/api/confirmations')
            .then(response => {
                if (response.status === 200) {
                    const listdata = JSON.parse((JSON.stringify(response.data)))
                    setPage(0)
                    setList(listdata.kojiList)
                    setProcessing_setList(false)
                } else if (response.status === 401) {
                    alert("認証に失敗しました。");
                    navigate("/");
                    setProcessing_setList(false)
                }
            }).catch((response) => {
                alert("一覧情報取得に失敗しました。", response.body);
                navigate("/");
                setProcessing_setList(false)
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    const {
        setKojiCD, setKojiNAME, setBeing, setFormData, setBeing_jyoto,
        setjyotoFormData, setBeing_shunko, setshunkoFormData,
        setCutTeimei, setChangeTeimei, kojiCD, setProcessChartPDF
    } = useContext(ConfirmationContext);

    const [processing, setProcessing] = useState(false);
    const disable_processing = useRef(false);
    const [processChart, setProcessChart] = useState(false);


    const onSubmit_none = (kojiCD, kojiNAME) => {

        if (disable_processing.current === true) { return; }

        disable_processing.current = true
        setProcessing(true)

        setKojiCD(kojiCD)
        setKojiNAME(kojiNAME)
        setBeing(true)

        authAxios
            .post('/api/confirmation/chakko/' + kojiCD + "?order=create", ChakkoInitialInputData)
            .then(response => {
                if (response.status === 200) {
                    authAxios
                        .get('/api/confirmation/chakko/' + kojiCD)
                        .then(response => {
                            if (response.status === 200) {
                                const getFormdata = JSON.parse((JSON.stringify(response.data)))
                                getFormdata.kakunin.haichiHenko1After = 0
                                getFormdata.kakunin.haichiHenko1Before = 0
                                getFormdata.kakunin.haichiHenko2After = 0
                                getFormdata.kakunin.haichiHenko2Before = 0
                                setFormData(getFormdata)
                                setCutTeimei(getFormdata.reportKojiName)
                                setChangeTeimei(getFormdata.reportKojiNameHonorific)

                                //DIGISIGN-549
                                //navigate("/confirmation/chakko/edit");
                                setProcessChart(true)
                            }
                        }).catch((response) => {
                            alert("作成中データの取得に失敗しました。", response.body);
                            setBeing(false)
                            setProcessing(false)
                        })
                    disable_processing.current = false
                }
            }).catch((response) => {
                alert("立会確認書の作成に失敗しました。", response.body);
                setBeing(false)
                setProcessing(false)
                disable_processing.current = false
            })
    };
    const onSubmit_being = (kojiCD, kojiNAME) => {
        if (processing === true) { return; }
        setProcessing(true)

        setKojiCD(kojiCD)
        setKojiNAME(kojiNAME)
        setBeing(true)

        authAxios
            .get('/api/confirmation/chakko/' + kojiCD)
            .then(response => {
                if (response.status === 200) {
                    const getFormdata = JSON.parse((JSON.stringify(response.data)))
                    setFormData(getFormdata)
                    setCutTeimei(getFormdata.reportKojiName)
                    setChangeTeimei(getFormdata.reportKojiNameHonorific)
                    //DIGISIGN-549
                    //navigate("/confirmation/chakko/edit");
                    setProcessing(false)
                    setProcessChart(true)
                }
            }).catch((response) => {
                alert("作成中データの取得に失敗しました。", response.body);
                setBeing(false)
                setProcessing(false)
            })
    };
    const onSubmit_done = (kojiCD, kojiNAME) => {
        setKojiCD(kojiCD)
        setKojiNAME(kojiNAME)
        navigate("/confirmation/chakko/chakko");
    };

    const onSubmit_none_jyoto = (kojiCD, kojiNAME) => {

        if (disable_processing.current === true) { return; }

        disable_processing.current = true
        setProcessing(true)

        setKojiCD(kojiCD)
        setKojiNAME(kojiNAME)
        setBeing_jyoto(true)

        authAxios
            .post('/api/confirmation/jyoto/' + kojiCD + "?order=create", JyotoInitialInputData)
            .then(response => {
                if (response.status === 200) {
                    authAxios
                        .get('/api/confirmation/jyoto/' + kojiCD)
                        .then(response => {
                            if (response.status === 200) {
                                const getjyotoFormdata = JSON.parse((JSON.stringify(response.data)))
                                setjyotoFormData(getjyotoFormdata)
                                setCutTeimei(getjyotoFormdata.reportKojiName)
                                setChangeTeimei(getjyotoFormdata.reportKojiNameHonorific)
                                navigate("/confirmation/jyoto/edit");
                            }
                        }).catch((response) => {
                            alert("作成中データの取得に失敗しました。", response.body);
                            setBeing_jyoto(false)
                            setProcessing(false)
                        })
                    disable_processing.current = false
                }
            }).catch((response) => {
                alert("立会確認書の作成に失敗しました。", response.body);
                setBeing_jyoto(false)
                setProcessing(false)
                disable_processing.current = false
            })
    };
    const onSubmit_being_jyoto = (kojiCD, kojiNAME) => {
        if (processing === true) { return; }
        setProcessing(true)

        setKojiCD(kojiCD)
        setKojiNAME(kojiNAME)
        setBeing_jyoto(true)

        authAxios
            .get('/api/confirmation/jyoto/' + kojiCD)
            .then(response => {
                if (response.status === 200) {
                    const getjyotoFormdata = JSON.parse((JSON.stringify(response.data)))
                    setjyotoFormData(getjyotoFormdata)
                    setCutTeimei(getjyotoFormdata.reportKojiName)
                    setChangeTeimei(getjyotoFormdata.reportKojiNameHonorific)
                    navigate("/confirmation/jyoto/edit");
                }
            }).catch((response) => {
                alert("作成中データの取得に失敗しました。", response.body);
                setBeing_jyoto(false)
                setProcessing(false)
            })
    };
    const onSubmit_done_jyoto = (kojiCD, kojiNAME) => {
        setKojiCD(kojiCD)
        setKojiNAME(kojiNAME)
        navigate("/confirmation/jyoto/jyoto");
    };

    const onSubmit_none_shunko = (kojiCD, kojiNAME) => {
        if (disable_processing.current === true) { return; }

        disable_processing.current = true
        setProcessing(true)

        setKojiCD(kojiCD)
        setKojiNAME(kojiNAME)
        setBeing_shunko(true)

        authAxios
            .post('/api/confirmation/shunko/' + kojiCD + "?order=create", ShunkoInitialInputData)
            .then(response => {
                if (response.status === 200) {
                    authAxios
                        .get('/api/confirmation/shunko/' + kojiCD)
                        .then(response => {
                            if (response.status === 200) {
                                const getshunkoFormdata = JSON.parse((JSON.stringify(response.data)))
                                setshunkoFormData(getshunkoFormdata)
                                setCutTeimei(getshunkoFormdata.reportKojiName)
                                setChangeTeimei(getshunkoFormdata.reportKojiNameHonorific)
                                navigate("/confirmation/shunko/edit");
                                setProcessing(false)
                            }
                            disable_processing.current = false
                        }).catch((response) => {
                            alert("作成中データの取得に失敗しました。", response.body);
                            setBeing_shunko(false)
                            setProcessing(false)
                        })
                    disable_processing.current = false
                }
            }).catch((response) => {
                alert("立会確認書の作成に失敗しました。", response.body);
                setBeing_shunko(false)
                setProcessing(false)
                disable_processing.current = false
            })
    };
    const onSubmit_being_shunko = (kojiCD, kojiNAME) => {
        if (processing === true) { return; }
        setProcessing(true)

        setKojiCD(kojiCD)
        setKojiNAME(kojiNAME)
        setBeing_shunko(true)

        authAxios
            .get('/api/confirmation/shunko/' + kojiCD)
            .then(response => {
                if (response.status === 200) {
                    const getshunkoFormdata = JSON.parse((JSON.stringify(response.data)))
                    setshunkoFormData(getshunkoFormdata)
                    setCutTeimei(getshunkoFormdata.reportKojiName)
                    setChangeTeimei(getshunkoFormdata.reportKojiNameHonorific)
                    navigate("/confirmation/shunko/edit");
                }
            }).catch((response) => {
                alert("作成中データの取得に失敗しました。", response.body);
                setBeing_shunko(false)
                setProcessing(false)

            })
    };
    const onSubmit_done_shunko = (kojiCD, kojiNAME) => {
        setKojiCD(kojiCD)
        setKojiNAME(kojiNAME)
        navigate("/confirmation/shunko/shunko");
    };

    const [processing_searchReset, setProcessing_searchReset] = useState(false);
    const onSubmit_Reset = () => {
        setProcessing_searchReset(true)
        setValue("customerCD", "")
        setValue("kojiCD", "")
        authAxios
            .get('/api/confirmations')
            .then(response => {
                if (response.status === 200) {
                    const listdata = JSON.parse((JSON.stringify(response.data)))
                    setPage(0)
                    setList(listdata.kojiList)
                    setProcessing_searchReset(false)
                } else if (response.status === 401) {
                    alert("認証に失敗しました。");
                    navigate("/");
                    setProcessing_searchReset(false)
                }
            }).catch((response) => {
                alert("絞り込み情報取得に失敗しました。", response.body);
                navigate("/");
                setProcessing_searchReset(false)
            });
        setTimeout(() => {
            setProcessing_searchReset(false);
        }, 10000);
    };

    const { control, handleSubmit, setValue, getValues } = useForm({
        defaultValues: {
            customerCD: "",
            kojiCD: "",
        },
    });

    const [processing_search, setProcessing_search] = useState(false);
    const onSubmit = () => {
        setProcessing_search(true)
        authAxios
            .get('api/confirmations?csCD=' + getValues("customerCD") + "&kojiCD=" + getValues("kojiCD"))
            .then((response) => {
                if (response.status === 200) {
                    const listdata = JSON.parse((JSON.stringify(response.data)))
                    setPage(0)
                    setList(listdata.kojiList)
                    setProcessing_search(false);
                } else if (response.status === 401) {
                    alert("認証に失敗しました。");
                    navigate("/");
                    setProcessing_search(false);
                }
            }).catch((response) => {
                alert("絞り込み情報取得に失敗しました。", response.body);
                setProcessing_search(false);
            })
        setTimeout(() => {
            setProcessing_search(false);
        }, 10000);
    };

    const onSubmit_Delete = () => {
        navigate("/confirmation/dellist");
    };


    React.useEffect(() => {

        if (!processChart) { return; }

        authAxios
            .get('api/confirmation/chakko/' + kojiCD + '/process-chart', { headers })
            .then(response => {
                if (response.status === 200) {
                    setProcessChartPDF(process.env.REACT_APP_API_URL + 'api/confirmation/chakko/' + kojiCD + '/process-chart')
                    navigate("/confirmation/chakko/edit");
                }
                setProcessing_setList(false)
            }).catch((response) => {
                if (response.response.status === 400) {
                    //データなし
                    setProcessChartPDF("")
                    navigate("/confirmation/chakko/edit");
                } else if (response.response.status === 401) {
                    alert("認証に失敗しました。");
                    setProcessing(false)
                    navigate("/");
                } else {
                    alert("PDFに失敗しました。", response.body);
                    navigate("/");
                }

                setProcessing(false)
            });


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [processChart]);


    return (
        <ConfirmationContents>
            <form onSubmit={handleSubmit()} >
                <LoadingDialog load={processing_setList} />
                <LoadingDialog load={processing} />
                <Grid container rowSpacing={{ xs: 1 }} columnSpacing={8}>
                    <Grid item xs="auto">
                        <SearchIcon />
                        <Controller
                            control={control}
                            name="customerCD"
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    id="customerCD"
                                    label="お客様No"
                                    variant="standard"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs="auto" >
                        <SearchIcon />
                        <Controller
                            control={control}
                            name="kojiCD"
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    id="kojiCD"
                                    label="工事コード"
                                    variant="standard"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs="auto" >
                        <Button
                            className="confButton"
                            type="submit"
                            variant="contained"
                            onClick={() => onSubmit()}
                            sx={{ transform: "scale(1.2)" }}
                            disabled={processing_search}
                        >
                            {processing_search ? "検索中" : "検索"}
                        </Button>
                        <LoadingDialog load={processing_search} />
                    </Grid>
                    <Grid item xs="auto">
                        <Button
                            color="error"
                            type="button"
                            variant="contained"
                            onClick={() => onSubmit_Reset()}
                            sx={{ transform: "scale(1.2)" }}
                            disabled={processing_searchReset}
                        >{processing_searchReset ? "リセット中" : "検索条件リセット"}
                        </Button>
                        <LoadingDialog load={processing_searchReset} />
                    </Grid>
                    {/*{userShozokuCD === "140" || userShozokuCD === "220" ?*/}
                    {/*    <Grid item xs="auto" >*/}
                    {/*        <Button*/}
                    {/*            type="button"*/}
                    {/*            onClick={() => onSubmit_Delete()}*/}
                    {/*            variant="contained"*/}
                    {/*            color="error"*/}
                    {/*            sx={{ transform: "scale(1.2)" }}*/}
                    {/*            style={{*/}
                    {/*                backgroundColor: "#C0C0C0",*/}
                    {/*                color: "#333333"*/}
                    {/*            }}*/}
                    {/*        >削除モード*/}
                    {/*        </Button>*/}
                    {/*    </Grid>*/}
                    {/*    : null*/}
                    {/*}*/}
                    <Grid item xs="auto" >
                        <Button
                            type="button"
                            onClick={() => onSubmit_Delete()}
                            variant="contained"
                            color="error"
                            sx={{ transform: "scale(1.2)" }}
                            style={{
                                backgroundColor: "#C0C0C0",
                                color: "#333333"
                            }}
                        >削除モード
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Paper sx={{ width: '100%', mb: 2, pt: 5, boxShadow: 0 }}>
                <TableFlame>
                    <Table
                        sx={{ minWidth: 900 }}
                        aria-labelledby="tableTitle"
                        align="center"
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={list?.length}
                        />
                        <TableBody>
                            {stableSort(list, getComparator(order, orderBy))
                                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                ?.map((row, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={row.kojiCD}
                                            sx={{ backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#EEEEEE" }}
                                        >
                                            <TableCell align="left" width="110px" sx={{ pr: 0 }}>{row.customerCD}</TableCell>
                                            <TableCell align="left" width="110px" sx={{ pl: 1, pr: 0 }}>{row.kojiCD}</TableCell>
                                            <TableCell align="left" width="100px" sx={{ pl: 0.8, pr: 0 }}>{row.agreeShitenName}</TableCell>
                                            <TableCell align="left" width="100px" sx={{ pl: 0.8, pr: 0 }}>{row.buildShitenName}</TableCell>
                                            <TableCell align="left" width="350px" sx={{ pl: 0.0, pr: 0 }}>{row.kojiName}</TableCell>
                                            {
                                                row.chakko === 0 ?
                                                    <CLTableCell align="center" width="120px" onClick={() => onSubmit_none(row.kojiCD, row.kojiName)}>
                                                        <Works disabled={processing}>
                                                            未作成
                                                            <BorderColorSharpIcon color="disabled" sx={{ mb: 1 }} />
                                                        </Works>
                                                    </CLTableCell>
                                                    :
                                                    (row.chakko === 1 ?
                                                        <CLTableCell align="center" width="100px" onClick={() => onSubmit_being(row.kojiCD, row.kojiName)} ><Works disabled={processing}>作成中　<BorderColorSharpIcon color="primary" sx={{ mb: 1 }} /></Works></CLTableCell>
                                                        :
                                                        <CLTableCell align="center" width="100px" onClick={() => onSubmit_done(row.kojiCD, row.kojiName)} ><Works disabled={processing} sx={{ pl: 1.5 }}>作成済み　{row.chakkoDate}<FileDownloadIcon /></Works></CLTableCell>
                                                    )
                                            }
                                            {
                                                row.jyoto === 0 ?
                                                    <CLTableCell align="center" width="100px" onClick={() => onSubmit_none_jyoto(row.kojiCD, row.kojiName)}><Works disabled={processing}>未作成　<BorderColorSharpIcon color="disabled" sx={{ mb: 1 }} /></Works></CLTableCell>
                                                    :
                                                    (row.jyoto === 1 ?
                                                        <CLTableCell align="center" width="100px" onClick={() => onSubmit_being_jyoto(row.kojiCD, row.kojiName)}><Works disabled={processing}>作成中　<BorderColorSharpIcon color="primary" sx={{ mb: 1 }} /></Works></CLTableCell>
                                                        :
                                                        <CLTableCell align="center" width="100px" onClick={() => onSubmit_done_jyoto(row.kojiCD, row.kojiName)}><Works disabled={processing} sx={{ p: 0, ml: 1 }}>作成済み　{row.jyotoDate}<FileDownloadIcon /></Works></CLTableCell>
                                                    )
                                            }
                                            {
                                                row.shunko === 0 ?
                                                    <CLTableCell align="center" width="100px" onClick={() => onSubmit_none_shunko(row.kojiCD, row.kojiName)}><Works disabled={processing}>未作成　<BorderColorSharpIcon color="disabled" sx={{ mb: 1 }} /></Works></CLTableCell>
                                                    :
                                                    (row.shunko === 1 ?
                                                        <CLTableCell align="center" width="100px" onClick={() => onSubmit_being_shunko(row.kojiCD, row.kojiName)}><Works disabled={processing}>作成中　<BorderColorSharpIcon color="primary" sx={{ mb: 1 }} /></Works></CLTableCell>
                                                        :
                                                        <CLTableCell align="center" width="100px" onClick={() => onSubmit_done_shunko(row.kojiCD, row.kojiName)}><Works disabled={processing} sx={{ p: 0, ml: 1 }}>作成済み　{row.shunkoDate}<FileDownloadIcon /></Works></CLTableCell>
                                                    )
                                            }
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableFlame>
                {list ?
                    <TablePagination
                        component="div"
                        count={list?.length}
                        labelRowsPerPage="表示件数"
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[5, 10, 25, 30, 50]}
                        page={page}
                        labelDisplayedRows={(from, to, count = list?.length) => (`全${count}中　${from.from}件～${from.to === -1 ? from.count : from.to}件を表示  `)}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    : <Grid />
                }
            </Paper>
        </ConfirmationContents >
    );
}
