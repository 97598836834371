import React, { useContext, useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useForm, Controller } from "react-hook-form";
import { UserInputData } from './StartRopeFrame';
import CheckBox from '..//CheckBox'
import Button from '@mui/material/Button';
import { Contents, FormTitle } from '../css/CSS.js';
import { useNavigate } from 'react-router-dom';
import useAuthAxios from "../../useAuthAxios";
import { ConfirmationContext } from "../../Confirmation";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import ja from 'date-fns/locale/ja'
import LoadingDialog from '../Loading';

export default function Kouki(props) {

    const { currentState, setCurrentState } = useContext(UserInputData);
    const { control, handleSubmit, getValues, formState: { errors }, watch, setValue } = useForm({
        defaultValues: { ...currentState.kouki },
        mode: 'onChange'
    });

    const [formNext, setFormNext] = useState(false)
    React.useEffect(() => {
        if (Object.keys(errors).length === 0) {
            if (check_chakkoDate === false && check_jyotoYoteiDate === false && check_shunkoYoteiDate === false && check_hikiwatasiYoteiDate === false) {
                if (formNext === true) {
                    props.handleNext();
                }
            }
        }
        setFormNext(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formNext]);

    const [formBack, setFormBack] = useState(false)
    React.useEffect(() => {
        if (Object.keys(errors).length === 0) {
            if (check_chakkoDate === false && check_jyotoYoteiDate === false && check_shunkoYoteiDate === false && check_hikiwatasiYoteiDate === false) {
                if (formBack === true) {
                    props.handleBack();
                }
            }
        }
        setFormBack(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formBack]);

    const { kojiCD, cutTeimei, changeTeimei, processChartPDF, setProcessChartPDF } = useContext(ConfirmationContext);
    const authAxios = useAuthAxios();
    const navigate = useNavigate();

    const [formPost, setFormPost] = useState(false)
    const [processing_post, setProcessing_post] = useState(false);
    React.useEffect(() => {
        if (Object.keys(errors).length === 0) {
            if (check_chakkoDate === false && check_jyotoYoteiDate === false && check_shunkoYoteiDate === false && check_hikiwatasiYoteiDate === false) {
                if (formPost === true) {
                    setProcessing_post(true);
                    authAxios
                        .post('/api/confirmation/chakko/' + kojiCD + "?order=update", currentState)
                        .then(response => {
                            if (response.status === 200) {
                                navigate("/confirmation/list");
                                setProcessing_post(false);
                            }
                        }).catch((response) => {
                            alert("保存に失敗しました。", response.body);
                            setProcessing_post(false);
                        })
                }
            }
        }
        setFormPost(false);
        setTimeout(() => {
            setProcessing_post(false);
        }, 10000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formPost]);

    const onSubmit = (action) => {

        const kouki = getValues();

        if (action === "post") {
            setFormPost(true);
            setCurrentState({ ...currentState, koukiEnable: "true", kouki, "reportKojiName": cutTeimei, "reportKojiNameHonorific": changeTeimei });
        } else if (action === "back") {
            setCurrentState({ ...currentState, koukiEnable: "true", kouki, "reportKojiName": cutTeimei, "reportKojiNameHonorific": changeTeimei });
            setFormBack(true)
        } else if (action === "next") {
            setCurrentState({ ...currentState, koukiEnable: "true", kouki, "reportKojiName": cutTeimei, "reportKojiNameHonorific": changeTeimei });
            setFormNext(true)
        }
    };

    const [value_chakkoDate, setchakkoDate] = React.useState("");

    const handleChange_chakkoDate = (newValue) => {
        setchakkoDate(newValue)
    }

    const [check_chakkoDate, setcheck_chakkoDate] = React.useState(false);
    const handleErr = (err) => {
        err === null ?
            setcheck_chakkoDate(false) : setcheck_chakkoDate(true)
    }
    const [value_jyotoYoteiDate, setjyotoYoteiDate] = React.useState("");

    const handleChange_jyotoYoteiDate = (newValue) => {
        setjyotoYoteiDate(newValue)
    }

    const [check_jyotoYoteiDate, setcheck_jyotoYoteiDate] = React.useState(false);
    const handleErr_jyotoYoteiDate = (err) => {
        err === null ?
            setcheck_jyotoYoteiDate(false) : setcheck_jyotoYoteiDate(true)
    }
    const [value_shunkoYoteiDate, setshunkoYoteiDate] = React.useState("");

    const handleChange_shunkoYoteiDate = (newValue) => {
        setshunkoYoteiDate(newValue)
    }

    const [check_shunkoYoteiDate, setcheck_shunkoYoteiDate] = React.useState(false);
    const handleErr_shunkoYoteiDate = (err) => {
        err === null ?
            setcheck_shunkoYoteiDate(false) : setcheck_shunkoYoteiDate(true)
    }
    const [value_hikiwatasiYoteiDate, sethikiwatasiYoteiDate] = React.useState("");

    const handleChange_hikiwatasiYoteiDate = (newValue) => {
        sethikiwatasiYoteiDate(newValue)
    }

    const [check_hikiwatasiYoteiDate, setcheck_hikiwatasiYoteiDate] = React.useState(false);
    const handleErr_hikiwatasiYoteiDate = (err) => {
        err === null ?
            setcheck_hikiwatasiYoteiDate(false) : setcheck_hikiwatasiYoteiDate(true)
    }



    const deleteProcessChart = () => {

        setProcessing_post(true);
        authAxios
            .delete('/api/confirmation/chakko/' + kojiCD + "/process-chart")
            .then(response => {
                if (response.status === 200) {
                    setValue("koteihyoKakunin", 0)
                    setProcessChartPDF("")
                    setProcessing_post(false);
                }
            }).catch((response) => {
                alert("削除に失敗しました。", response.body);
                setProcessing_post(false);
            })

        setFormPost(false);

        setTimeout(() => {
            setProcessing_post(false);
        }, 10000);


    };

    return (
        <Contents >
            <form onSubmit={handleSubmit(onSubmit)} >
                <Grid container columns={12} columnSpacing={{ xs: 1, sm: 3, md: 3 }}>
                    <Grid>
                        <FormTitle>
                            工期
                        </FormTitle>
                    </Grid>
                </Grid>
                <Grid container columns={12} rowSpacing={3} columnSpacing={{ xs: 1, sm: 3, md: 3 }} sx={{ pt: 2 }}>
                    <Grid item xs={12} >
                        <Grid container columns={12} spacing={4} >
                            <Grid item xs={2} alignContent="flex-start" sx={{ mt: 2 }}>
                                <Typography sx={{ transform: "scale(1.3)" }}>着工日</Typography>
                            </Grid>
                            <Grid item xs={10} alignContent="flex-start" sx={{ mt: 1 }}>
                                <Controller
                                    control={control}
                                    name="chakkoDate"
                                    render={({ field }) => (
                                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja} dateFormats={{ monthAndYear: 'yyyy年MM月' }}>
                                            <DesktopDatePicker
                                                openTo="day"
                                                views={['year', 'month', 'day']}
                                                value={value_chakkoDate}
                                                onChange={handleChange_chakkoDate}
                                                inputFormat="yyyy/MM/dd"
                                                inputProps={{ style: { padding: '6px' } }}
                                                onError={(err) => handleErr(err)}
                                                {...field}
                                                renderInput={(params) =>
                                                    <TextField {...params} sx={{ width: 170 }}
                                                        error={check_chakkoDate}
                                                    />}
                                            />
                                        </LocalizationProvider>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2} alignContent="flex-start" sx={{ mt: 1 }}>
                                <Typography sx={{ transform: "scale(1.3)" }}>上棟予定日</Typography>
                            </Grid>
                            <Grid item xs={10} alignContent="flex-start" sx={{}}>
                                <Controller
                                    control={control}
                                    name="jyotoYoteiDate"
                                    render={({ field }) => (
                                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja} dateFormats={{ monthAndYear: 'yyyy年MM月' }}>
                                            <DesktopDatePicker
                                                openTo="day"
                                                views={['year', 'month', 'day']}
                                                value={value_jyotoYoteiDate}
                                                onChange={handleChange_jyotoYoteiDate}
                                                inputFormat="yyyy/MM/dd"
                                                inputProps={{ style: { padding: '6px' } }}
                                                onError={(err) => handleErr_jyotoYoteiDate(err)}
                                                {...field}
                                                renderInput={(params) =>
                                                    <TextField {...params} sx={{ width: 170 }}
                                                        error={check_jyotoYoteiDate}
                                                    />}
                                            />
                                        </LocalizationProvider>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2} alignContent="flex-start" sx={{ mt: 1 }}>
                                <Typography sx={{ transform: "scale(1.3)" }}>竣工予定日</Typography>
                            </Grid>
                            <Grid item xs={10} alignContent="flex-start" sx={{}}>
                                <Controller
                                    control={control}
                                    name="shunkoYoteiDate"
                                    render={({ field }) => (
                                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja} dateFormats={{ monthAndYear: 'yyyy年MM月' }}>
                                            <DesktopDatePicker
                                                openTo="day"
                                                views={['year', 'month', 'day']}
                                                value={value_shunkoYoteiDate}
                                                onChange={handleChange_shunkoYoteiDate}
                                                inputFormat="yyyy/MM/dd"
                                                inputProps={{ style: { padding: '6px' } }}
                                                onError={(err) => handleErr_shunkoYoteiDate(err)}
                                                {...field}
                                                renderInput={(params) =>
                                                    <TextField {...params} sx={{ width: 170 }}
                                                        error={check_shunkoYoteiDate}
                                                    />}
                                            />
                                        </LocalizationProvider>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2} alignContent="flex-start" sx={{ mt: 1 }}>
                                <Typography sx={{ transform: "scale(1.3)" }}>引渡予定日</Typography>
                            </Grid>
                            <Grid item xs={10} alignContent="flex-start" sx={{}}>
                                <Controller
                                    control={control}
                                    name="hikiwatasiYoteiDate"
                                    render={({ field }) => (
                                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja} dateFormats={{ monthAndYear: 'yyyy年MM月' }}>
                                            <DesktopDatePicker
                                                openTo="day"
                                                views={['year', 'month', 'day']}
                                                value={value_hikiwatasiYoteiDate}
                                                onChange={handleChange_hikiwatasiYoteiDate}
                                                inputFormat="yyyy/MM/dd"
                                                inputProps={{ style: { padding: '6px' } }}
                                                onError={(err) => handleErr_hikiwatasiYoteiDate(err)}
                                                {...field}
                                                renderInput={(params) =>
                                                    <TextField {...params} sx={{ width: 170 }}
                                                        error={check_hikiwatasiYoteiDate}
                                                    />}
                                            />
                                        </LocalizationProvider>
                                    )}
                                />
                            </Grid>
                            <Grid container columns={12} rowSpacing={3} columnSpacing={{ xs: 1, sm: 3, md: 3 }} sx={{ pt: 8, pl: 6.5 }}>
                                {processChartPDF ?
                                    <Grid item xs={12} alignContent="flex-start" >
                                        <embed
                                            type="application/pdf"
                                            src={processChartPDF}
                                            width="100%"
                                            style={{
                                                border: "2px solid",
                                                borderColor: "#2c2c2c",
                                                height: "60vh",
                                                width: "100%",
                                            }}
                                        />
                                    </Grid>
                                    : null
                                }
                                <Grid item xs={5} >
                                    <Controller
                                        control={control}
                                        name="koteihyoKakunin"
                                        render={({ field: { onChange, value } }) => (
                                            <FormControlLabel
                                                sx={{ transform: "scale(1.3)", pl: 0.5, pt: 0.5 }}
                                                control={
                                                    <CheckBox
                                                        setCheckedvalue={onChange}
                                                        checkedvalue={value}
                                                        color='primary'
                                                    />
                                                }
                                                label={<Typography sx={{ transform: "scale(1.1)", pl: 1.1 }} style={watch("koteihyoKakunin") === 2 ? { textDecoration: "line-through" } : {}}>工程表確認</Typography>}
                                                labelPlacement="end"
                                                disabled={processChartPDF ? false : true}
                                            />
                                        )}
                                    />
                                </Grid>
                                {processChartPDF ?
                                    <Grid item xs={6} >
                                        <Button variant="contained" color="error" size="large" type="button" onClick={() => deleteProcessChart()} >
                                            工程表を削除
                                        </Button>
                                    </Grid>
                                    : null
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container columns={16} rowSpacing={3} columnSpacing={{ xs: 1, sm: 3, md: 3 }} sx={{ pt: 6 }}>
                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" size="large" type="button" onClick={() => onSubmit("post")} disabled={processing_post}>
                                {processing_post ? "処理中" : "保存して一覧へ"}
                            </Button>
                            <LoadingDialog load={processing_post} />
                        </Grid>
                        <Grid item xs={2} >
                            <Button variant="contained" color="primary" size="large" type="button" onClick={() => onSubmit("back")} >
                                戻る
                            </Button>
                        </Grid>
                        <Grid item xs={2} >
                            <Button variant="contained" color="primary" size="large" type="button" onClick={() => onSubmit("next")}>
                                次へ
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </Contents>
    );
}
